import React from "react";
import style from "./form.module.css";

const Infos = ({ infos }) => {
  return (
    <div className={style.infoList}>
      {infos?.map((info) => (
        <div key={info} className={style.info}>
          <div className={style.bullet}></div>
          <p>{info}</p>
        </div>
      ))}
    </div>
  );
};

export default Infos;
