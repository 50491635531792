import React from "react";
import Card from "./Card";
import Container from "./Container";

const Counting = () => {
  return (
    <Container>
      <Card num={200} lable="Orders today" />
      <Card num={6000} lable="This week" />
    </Container>
  );
};

export default Counting;
