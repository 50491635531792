import React from "react";
import style from "./form.module.css"

const Buttons = ({ buttons }) => {
  return (
    <div className={style.formButtons}>
      {buttons?.map((btn) => (
        <div key={btn.lable} onClick={btn.onClick}>
          {btn.lable}
        </div>
      ))}
    </div>
  );
};

export default Buttons;
