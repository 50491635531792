import React from "react";
import style from "./ad-image.module.css";

const Container = ({ children }) => {
  return (
    <div className={style.adContentDisplay}>
      <div className={style.imageContainer}>{children}</div>
    </div>
  );
};

export default Container;
