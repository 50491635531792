import React from "react";
import style from "./counting.module.css"

const Card = ({num, lable}) => {
  return (
    <div className={style.counting}>
      <div className={style.countingText}>
        <p>+{num}</p>
        <span>{lable}</span>
      </div>
    </div>
  );
};

export default Card;
