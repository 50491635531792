import React from 'react'
import style from "./form.module.css"

const Container = ({children}) => {
  return (
    <div className={style.formContainer}>
      {children}
    </div>
  )
}

export default Container