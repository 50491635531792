import React from "react";
import Layout from "../../components/Layout";
import AdImage from "../../components/AdImage";
import Counting from "../../components/Counting";
import Form from "../../components/Form";

const MainPage = () => {
  return (
    <Layout>
      <AdImage />
      <Counting />
      <Form />
    </Layout>
  );
};

export default MainPage;
