import React from "react";
import Container from "./Container";
import Title from "./Title";
import Input from "./Input";
import Buttons from "./Buttons";
import Infos from "./Infos";

const buttons = [
  {
    lable: "Doxycyckine",
    onClick: () => {},
  },
  {
    lable: "Pilocarpine",
    onClick: () => {},
  },
  {
    lable: "Fluoride",
    onClick: () => {},
  },
];

const infos = ["Allow sales representative to contact you."];

const Form = () => {
  return (
    <>
      <Container>
        <Title title={"Bulk Medicine Booking"} />
        <Input placeholder={"Enter name"} />
        <Input placeholder={"Enter phone number"} />
        <Input placeholder={"Enter Medicine Name"} />
        <Buttons buttons={buttons} />
        <Infos infos={infos} />
      </Container>
    </>
  );
};

export default Form;
