import React from 'react'
import style from "./layout.module.css"

const Layout = ({children}) => {
  return (
    <div class={style.mainContainer}>
      {children}
    </div>
  )
}

export default Layout