import React from "react";
import Container from "./Container";
import Image from "./Image";
import Image1 from "../../Assets/images/Picture1.png"

const AdImage = () => {
  return (
    <Container>
      <Image src={Image1} alt={"image"} />
      <Image src={Image1} alt={"image"} />
    </Container>
  );
};

export default AdImage;
