import React from "react";
import style from "./ad-image.module.css";

const Image = ({src, alt}) => {
  return (
    <div className={style.image}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default Image;
