import React from "react";
import style from "./form.module.css";

const Input = ({ placeholder }) => {
  return (
    <input type="text" placeholder={placeholder} className={style.inputText} />
  );
};

export default Input;
