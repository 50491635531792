import React from "react";
import style from "./counting.module.css"

const Container = ({children}) => {
  return (
    <div className={style.countingContainer}>
      {children}
    </div>
  );
};

export default Container;
